h6 {
    margin-bottom: 0.32rem !important;
}

.login-form {
    max-width: 300px;
    margin: auto;
}

.bx--modal {
    height: calc(100vh - 50px);
}

.navbar-button {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 2rem;
    color: #f3f3f3;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 1;
}
.navbar-button:hover {
    background-color: #555555;
}
.navbar-button-main {
    background-color: unset !important;
    font-size: 1rem;
}
.navbar-right {
    display: flex;
    justify-content: flex-end;
    flex: 1 1;
}
svg {
    fill: #f3f3f3;
}
.tox-notifications-container {
    display: none
}
.bx--modal.is-visible {
    z-index: 1000 !important;
}
.bx--form-item.bx--checkbox-wrapper {
    display: inline-block;
    margin-right: 10px;
}

@keyframes expand {
    from {
        opacity: 0;
    }
}

.Node {
    animation: expand .5s ease-in-out;
    margin-left: 3rem;
    padding: 1rem;
    margin: 1rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}

.Navbar {
    height: 3rem;
    background-color: #252525;
    display: flex;
}

.ServiceTile {
    height: 100%;
    text-decoration: none;
    text-align: center;
}

.ServiceTile > div {
    height: 100%;
}

.ServiceTile > div > img {
    margin: 15px;
    max-width: 100px;
    max-height: 100px;
}

.ServiceTile {
    animation-duration: 0.5
}

.node::before {
    content: '';
    display: block;
    border-bottom: 2px solid #8897a2;
    border-left: 2px solid #8897a2;
    border-bottom-left-radius: 50%;
    width: 22px;
    height: 47px;
    left: -24px;
    top: 0;
    padding: 0;
    position: absolute;
}

.node-container::before {
    content: '';
    position: absolute;
    display: block;
    border-left: 2px solid #8897a2;
    color: #41d6c3;
    width: 0;
    height: 18px;
    transform: translate(10px, -16px);
}

.node-container > .node-children > .node-container.not-last-node::after {
    content: '';
    position: absolute;
    display: block;
    border-left: 2px solid #8897a2;
    color: #41d6c3;
    width: 0;
    height: calc(100% - 40px);
    top: 230px;
    left: 0;
    transform: translate(10px, -200px);
}

.node-container {
    position: relative;
}

.node {
    padding: 0 !important;
    display: flex !important;
    margin-left: 33px;
    width: 450px;
    height: 90px;
    color: #5a6872;
    border: 1px solid #8897a2 !important;
    /* z-index: 0; */
}

.node.node-selected {
    background-color: #deedf7 !important;
    border: 1px solid #3d70b2 !important;
}

.node.node-source {
    color: #152934 !important;
    background-color: #3d70b2 !important;
    opacity: 0.5;
}

.node.node-source > .node__contents {
    color: #152934 !important;
    cursor: not-allowed !important;
}

.node.node-jump {
    border-color: white !important;
    box-shadow: 0px 5px 10px 0px white;
    color: #152934 !important;
    cursor: pointer !important;
}

.node.node-jump > .bx--link {
    margin-left: 30px;
    align-self: center;
    font-size: 1rem;
}

.node__expander {
    width: 36px;
}

.node__expander>button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    border-right: 1px solid #8897a2;
}

.node--expanded:not(.node--folder) .node__expander>button>svg {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.node__contents {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 8px 16px;
    max-width: 400px;
    text-align: left;
    color: #5a6872;
    cursor: pointer;
}

.bx--btn > svg {
    margin-left: 10px;
}

.bx--btn.btn-icon-only {
    border-width: 0;
    margin-left: 0;
}

.bx--btn.btn-icon-only > svg {
    margin-left: 0px;
}

.bx--btn--secondary:not(:hover) > svg, .bx--btn--secondary:not(:focus) > svg {
    animation-duration: 0.5s;
    animation-name: slideout;
    fill: #3d70b2;
}

.bx--btn--secondary:hover > svg, .bx--btn--secondary:focus > svg {
    animation-duration: 0.5s;
    animation-name: slidein;
    fill: #f3f3f3;
}

@keyframes slidein {
    from {
        fill: #3d70b2;
    }

    to {
        fill: #f3f3f3;
    }
}

@keyframes slideout {
    from {
        fill: #f3f3f3;
    }

    to {
        fill: #3d70b2;
    }
}

.bx--batch-actions {
    z-index: 500 !important;
}

.bx--modal-container.bx--modal-container-lg {
    max-width: 90%;
    width: 100%;
    max-height: 95%;
    height: 100%;
}

.bx--modal-content {
    height: 100%;
}

.tox-statusbar__branding {
    display: none;
}

.bx--tile > h3 {
    margin: 1rem;
}

.DialogToolbar {
    display: flex;
}

.DialogToolbar > button {
    margin-left: 15px;
}

.bx--header__global>.bx--header__submenu {
    list-style-type: none;
}

.bx--text-input {
    min-width: unset !important;
}

.node-folder__stack::before {
    top: 8px;
    left: 39px;
}

.node-folder__stack::before, .node-folder__stack::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 90px;
    max-width: 450px;
    border: 1px solid #8d8d8d;
    background-color: #f4f4f4;
}

.node-folder__stack::after {
    top: 3px;
    left: 35px;
}

.node-folder__stack::before, .node-folder__stack::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 90px;
    max-width: 450px;
    border: 1px solid #8d8d8d;
    background-color: #f4f4f4;
}

.carbon--custom-form > .bx--modal-container {
    max-width: 90%;
    width: 100%;
    max-height: 95%;
    height: 100%;
  }

.bx--chart-holder {
    background-color: unset !important;
}

.bx--toolbar-search-container {
    width: 100%;
}

.btn-inline {
    display: inline;
}

.bx--table-header-label {
    width: 100%;
    text-align: initial;
}

.bx--list-box__menu {
    max-height: 12.5rem !important;
}