.bx--header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    background-color: #252525;
    color: #f3f3f3;
    z-index: 600;
}

.bx--header__action {
    // @include button-reset();
    width: 3rem;
    height: 3rem;
    border: 3px solid transparent;
}

.bx--header__action > svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active > svg.bx--navigation-menu-panel-expand-icon {
    display: none;
}

.bx--header__action--active > svg.bx--navigation-menu-panel-collapse-icon {
    display: inline;
}

.bx--header__action > svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active > svg.bx--navigation-menu-panel-expand-icon {
    display: none;
}

.bx--header__action--active > svg.bx--navigation-menu-panel-collapse-icon {
    display: inline;
}

.bx--header__action:hover {
    background-color: #333333;
}

.bx--header__action--active,
.bx--header__action--active:hover {
    background-color: #0062ff;
}

.bx--header__action--active,
.bx--header__action:focus {
    border-color: #0062ff;
    outline: none;
}

.bx--header__action > svg {
    fill: #f3f3f3;
}

//--------------------------------------------------------------------------
// Header - Name
//--------------------------------------------------------------------------
a.bx--header__name {
    // @include type-style('body-short-01');
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 20px;
    user-select: none;
}

.bx--header__name--prefix {
    font-weight: 400;
}

a.bx--header__name,
a.bx--header__name:hover {
    color: #f3f3f3;
}

a.bx--header__name:focus {
    outline: none;
    box-shadow: inset 0 0 0 4px #0062ff;
}

//--------------------------------------------------------------------------
// Header - Navigation
//--------------------------------------------------------------------------
.bx--header__nav {
    height: 100%;
}

.bx--header__menu-bar[role='menubar'] {
    display: flex;
    margin-left: 0.5rem;
    height: 100%;
}

a.bx--header__menu-item[role='menuitem'] {
    display: flex;
    align-items: center;
    color: #f3f3f3;
    padding: 0 1rem;
    // Used for links that are directly in the menubar to span the full height
    height: 100%;
    // Text styles
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    // Reset link styles and make sure the text isn't selectable
    text-decoration: none;
    user-select: none;
    // Used for focus styles
    border: 4px solid transparent;
}

a.bx--header__menu-item[role='menuitem']:hover {
    background-color: #333333;
}

.bx--header__action:active,
a.bx--header__menu-item[role='menuitem']:active {
    background-color: #3d3d3d;
}

a.bx--header__menu-item[role='menuitem']:focus {
    border-color: #0062ff;
    outline: none;
}

.bx--header__submenu {
    position: relative;
}

.bx--header__menu-title[role='menuitem'][aria-haspopup='true'] {
    position: relative;
}

.bx--header__menu-title[role='menuitem'][aria-expanded='true'] {
    background-color: #3d3d3d;
    // Note: needs to be higher than menu. Adding 1 here instead of moving to
    // the next level.
    z-index: #{600 + 1};
}

.bx--header__menu-title[role='menuitem'][aria-expanded='true'] > .bx--header__menu-arrow {
    transform: rotate(180deg);
}

.bx--header__menu[role='menu'] {
    display: none;
}

.bx--header__menu-title[role='menuitem'][aria-expanded='true'] + .bx--header__menu {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 12.5rem;
    flex-direction: column;
    transform: translateY(100%);
    background-color: #3d3d3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    z-index: 600;
}

.bx--header__menu .bx--header__menu-item[role='menuitem'] {
    height: 3rem;
}

.bx--header__menu .bx--header__menu-item[role='menuitem']:hover {
    background-color: #4c4c4c;
    color: #fff;
}

.bx--header__menu-arrow {
    fill: #f3f3f3;
    margin-left: 0.5rem;
}

//--------------------------------------------------------------------------
// Header - Global
//--------------------------------------------------------------------------
.bx--header__global {
    display: flex;
    justify-content: flex-end;
    flex: 1 1 0%;
    height: 100%;
}

//--------------------------------------------------------------------------
// Header - Skip to content
//--------------------------------------------------------------------------
.bx--skip-to-content {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
}

.bx--skip-to-content:focus {
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: auto;
    height: 3rem;
    clip: auto;
    border: 4px solid #0062ff;
    z-index: 9999;
    background-color: #252525;
    color: #f3f3f3;
    outline: none;
    padding: 0 1rem;
}
.bx--header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    background-color: #252525;
    color: #f3f3f3;
    z-index: 600;
}

.bx--header__action {
    // @include button-reset();
    width: 3rem;
    height: 3rem;
    border: 3px solid transparent;
}

.bx--header__action > svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active > svg.bx--navigation-menu-panel-expand-icon {
    display: none;
}

.bx--header__action--active > svg.bx--navigation-menu-panel-collapse-icon {
    display: inline;
}

.bx--header__action > svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active > svg.bx--navigation-menu-panel-expand-icon {
    display: none;
}

.bx--header__action--active > svg.bx--navigation-menu-panel-collapse-icon {
    display: inline;
}

.bx--header__action:hover {
    background-color: #333333;
}

.bx--header__action--active,
.bx--header__action--active:hover {
    background-color: #0062ff;
}

.bx--header__action--active,
.bx--header__action:focus {
    border-color: #0062ff;
    outline: none;
}

.bx--header__action > svg {
    fill: #f3f3f3;
}

//--------------------------------------------------------------------------
// Header - Name
//--------------------------------------------------------------------------
a.bx--header__name {
    // @include type-style('body-short-01');
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 20px;
    user-select: none;
}

.bx--header__name--prefix {
    font-weight: 400;
}

a.bx--header__name,
a.bx--header__name:hover {
    color: #f3f3f3;
}

a.bx--header__name:focus {
    outline: none;
    box-shadow: inset 0 0 0 4px #0062ff;
}

//--------------------------------------------------------------------------
// Header - Navigation
//--------------------------------------------------------------------------
.bx--header__nav {
    height: 100%;
}

.bx--header__menu-bar[role='menubar'] {
    display: flex;
    margin-left: 0.5rem;
    height: 100%;
}

a.bx--header__menu-item[role='menuitem'] {
    display: flex;
    align-items: center;
    color: #f3f3f3;
    padding: 0 1rem;
    // Used for links that are directly in the menubar to span the full height
    height: 100%;
    // Text styles
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    // Reset link styles and make sure the text isn't selectable
    text-decoration: none;
    user-select: none;
    // Used for focus styles
    border: 4px solid transparent;
}

a.bx--header__menu-item[role='menuitem']:hover {
    background-color: #333333;
}

.bx--header__action:active,
a.bx--header__menu-item[role='menuitem']:active {
    background-color: #3d3d3d;
}

a.bx--header__menu-item[role='menuitem']:focus {
    border-color: #0062ff;
    outline: none;
}

.bx--header__submenu {
    position: relative;
}

.bx--header__menu-title[role='menuitem'][aria-haspopup='true'] {
    position: relative;
}

.bx--header__menu-title[role='menuitem'][aria-expanded='true'] {
    background-color: #3d3d3d;
    // Note: needs to be higher than menu. Adding 1 here instead of moving to
    // the next level.
    z-index: #{600 + 1};
}

.bx--header__menu-title[role='menuitem'][aria-expanded='true'] > .bx--header__menu-arrow {
    transform: rotate(180deg);
}

.bx--header__menu[role='menu'] {
    display: none;
}

.bx--header__menu-title[role='menuitem'][aria-expanded='true'] + .bx--header__menu {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 12.5rem;
    flex-direction: column;
    transform: translateY(100%);
    background-color: #3d3d3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    z-index: 600;
}

.bx--header__menu .bx--header__menu-item[role='menuitem'] {
    height: 3rem;
}

.bx--header__menu .bx--header__menu-item[role='menuitem']:hover {
    background-color: #4c4c4c;
    color: #fff;
}

.bx--header__menu-arrow {
    fill: #f3f3f3;
    margin-left: 0.5rem;
}

//--------------------------------------------------------------------------
// Header - Global
//--------------------------------------------------------------------------
.bx--header__global {
    display: flex;
    justify-content: flex-end;
    flex: 1 1 0%;
    height: 100%;
}

//--------------------------------------------------------------------------
// Header - Skip to content
//--------------------------------------------------------------------------
.bx--skip-to-content {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
}

.bx--skip-to-content:focus {
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: auto;
    height: 3rem;
    clip: auto;
    border: 4px solid #0062ff;
    z-index: 9999;
    background-color: #252525;
    color: #f3f3f3;
    outline: none;
    padding: 0 1rem;
}
.bx--header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;
    background-color: #252525;
    color: #f3f3f3;
    z-index: 600;
  }

  .bx--header__action {
    // @include button-reset();
    width: 3rem;
    height: 3rem;
    border: 3px solid transparent;
  }

  .bx--header__action > svg.bx--navigation-menu-panel-collapse-icon,
  .bx--header__action--active > svg.bx--navigation-menu-panel-expand-icon {
    display: none;
  }

  .bx--header__action--active > svg.bx--navigation-menu-panel-collapse-icon {
    display: inline;
  }

  .bx--header__action > svg.bx--navigation-menu-panel-collapse-icon,
  .bx--header__action--active > svg.bx--navigation-menu-panel-expand-icon {
    display: none;
  }

  .bx--header__action--active > svg.bx--navigation-menu-panel-collapse-icon {
    display: inline;
  }

  .bx--header__action:hover {
    background-color: #333333;
  }

  .bx--header__action--active,
  .bx--header__action--active:hover {
    background-color: #0062ff;
  }

  .bx--header__action--active,
  .bx--header__action:focus {
    border-color: #0062ff;
    outline: none;
  }

  .bx--header__action > svg {
    fill: #f3f3f3;
  }

  //--------------------------------------------------------------------------
  // Header - Name
  //--------------------------------------------------------------------------
  a.bx--header__name {
    // @include type-style('body-short-01');
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 20px;
    user-select: none;
  }

  .bx--header__name--prefix {
    font-weight: 400;
  }

  a.bx--header__name,
  a.bx--header__name:hover {
    color: #f3f3f3;
  }

  a.bx--header__name:focus {
    outline: none;
    box-shadow: inset 0 0 0 4px #0062ff;
  }

  //--------------------------------------------------------------------------
  // Header - Navigation
  //--------------------------------------------------------------------------
  .bx--header__nav {
    height: 100%;
  }

  .bx--header__menu-bar[role='menubar'] {
    display: flex;
    margin-left: 0.5rem;
    height: 100%;
  }

  a.bx--header__menu-item[role='menuitem'] {
    display: flex;
    align-items: center;
    color: #f3f3f3;
    padding: 0 1rem;
    // Used for links that are directly in the menubar to span the full height
    height: 100%;
    // Text styles
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18px;
    // Reset link styles and make sure the text isn't selectable
    text-decoration: none;
    user-select: none;
    // Used for focus styles
    border: 4px solid transparent;
  }

  a.bx--header__menu-item[role='menuitem']:hover {
    background-color: #333333;
  }

  .bx--header__action:active,
  a.bx--header__menu-item[role='menuitem']:active {
    background-color: #3d3d3d;
  }

  a.bx--header__menu-item[role='menuitem']:focus {
    border-color: #0062ff;
    outline: none;
  }

  .bx--header__submenu {
    position: relative;
  }

  .bx--header__menu-title[role='menuitem'][aria-haspopup='true'] {
    position: relative;
  }

  .bx--header__menu-title[role='menuitem'][aria-expanded='true'] {
    background-color: #3d3d3d;
    // Note: needs to be higher than menu. Adding 1 here instead of moving to
    // the next level.
    z-index: #{600 + 1};
  }

  .bx--header__menu-title[role='menuitem'][aria-expanded='true'] > .bx--header__menu-arrow {
    transform: rotate(180deg);
  }

  .bx--header__menu[role='menu'] {
    display: none;
  }

  .bx--header__menu-title[role='menuitem'][aria-expanded='true'] + .bx--header__menu {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 12.5rem;
    flex-direction: column;
    transform: translateY(100%);
    background-color: #3d3d3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    z-index: 600;
  }

  .bx--header__menu .bx--header__menu-item[role='menuitem'] {
    height: 3rem;
  }

  .bx--header__menu .bx--header__menu-item[role='menuitem']:hover {
    background-color: #4c4c4c;
    color: #fff;
  }

  .bx--header__menu-arrow {
    fill: #f3f3f3;
    margin-left: 0.5rem;
  }

  //--------------------------------------------------------------------------
  // Header - Global
  //--------------------------------------------------------------------------
  .bx--header__global {
    display: flex;
    justify-content: flex-end;
    flex: 1 1 0%;
    height: 100%;
  }

  //--------------------------------------------------------------------------
  // Header - Skip to content
  //--------------------------------------------------------------------------
  .bx--skip-to-content {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    visibility: visible;
    white-space: nowrap;
  }

  .bx--skip-to-content:focus {
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: auto;
    height: 3rem;
    clip: auto;
    border: 4px solid #0062ff;
    z-index: 9999;
    background-color: #252525;
    color: #f3f3f3;
    outline: none;
    padding: 0 1rem;
  }
  
.bx--header~.bx--content {
    margin-top: 3rem;
}
.bx--content {
    transform: translateZ(0);
    will-change: margin-left;
}

.bx--graph-header {
    font-weight: 300;
    font-size: 24px;
}

.axis path {
    stroke: #5A6872;
}

.tick line {
    stroke: #5A6872;
}

.tick text {
    fill: #5A6872;
}

.graph-container {
    position: relative;
    overflow-x: scroll;
}

.tooltip {
    font-weight: 700;
    padding-left: 1rem 2rem;
    background-color: #fff;
    position: absolute;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .1); 
    border: 1px solid #DFE3E6;
    padding: .25rem .5rem;
    pointer-events: none;
    display: none;
     
    &:after {
        content: '';
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #fff;
    }
}

.y path {
    display: none;
}

.label {
    font-size: 10px;
    font-weight: 700;
    fill: #5A6872;
    text-anchor: middle;
}


.bx--graph-header {
    font-weight: 300;
    font-size: 24px;
}

.bx--graph-overlay {
    fill: #3d70b2;
    opacity: .1;
    display: none;
    pointer-events: none;
}

.bx--line {
    stroke-width: 2;
    stroke: #00a68f;
    fill: none;
    pointer-events: none;
}

.bx--axis path {
    stroke: #5A6872;
}

.tick line {
    stroke: #5A6872;
}

.tick text {
    fill: #5A6872;
}

.bx--graph-container {
    position: relative;
}

.bx--axis--y path {
    display: none;
}

.bx--graph-label {
    font-size: 10px;
    font-weight: 700;
    fill: #5A6872;
    text-anchor: middle;
}
